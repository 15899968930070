<template>
  <div class="content">
    <PageHeader title="Short Codes">
      <template v-slot:actions>
        <li>
          <ButtonComponent variant="primary">
            <router-link :to="'/admin/short-codes/create'">
              <span class="material-symbols-outlined">
                add
              </span>
            </router-link>
          </ButtonComponent>
        </li>
      </template>
    </PageHeader>
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <CardComponent variant="tertiary">
            <TableComponent :fields="fields" :items="shortCodes" :busy="!fetched">
              <template #cell(providers)="slotProps">
                <BadgeComponent v-for="provider in slotProps.data" :key="provider.id" variant="light" class="ml-1 mt-1">
                  {{ provider.name }}
                </BadgeComponent>
              </template>
              <template #cell()="slotProps">
                <DropdownComponent no-caret variant="link">
                  <template #button>
                    <span class="material-symbols-outlined">more_horiz</span>
                  </template>
                  <router-link :to="`/admin/short-codes/${slotProps.item.id}`" class="dropdown-item">
                    {{ $tc('app.edit') }}
                  </router-link>
                  <a class="dropdown-item" href="javascript:void(0)" @click="remove(slotProps.item.id)">{{
                    $tc('app.delete') }}</a>
                </DropdownComponent>
              </template>
            </TableComponent>
            <PaginationComponent v-model="form.page" :total-pages="pages" @change="fetch"></PaginationComponent>
          </CardComponent>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import SmsService from '@/services/admin/sms.service';
import Swal from 'sweetalert2';
import CardComponent from '@/components/CardComponent.vue';
import TableComponent from '@/components/TableComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';
import DropdownComponent from '@/components/DropdownComponent.vue';
import PaginationComponent from '@/components/PaginationComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
  name: 'ShortCodesIndex',
  components: {
    PageHeader,
    CardComponent,
    TableComponent,
    BadgeComponent,
    DropdownComponent,
    PaginationComponent,
    ButtonComponent,
  },
  data() {
    return {
      fetched: false,
      fields: [
        'name',
        { key: 'number', label: 'Short Code' },
        'deliverability',
        { key: 'providers', label: this.$tc('app.provider', 2) },
        '',
      ],
      shortCodes: [],
      form: {
        name: '',
        page: 1,
      },
      pages: 1,
      isSending: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      SmsService.getShortCodes(this.form).then(
        (response) => {
          this.fetched = true;
          this.shortCodes = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: this.$t('customer-component.warning-product'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetched = false;
          SmsService.deleteShortCode(id).then(
            () => {
              this.fetch(this.form.page);
              Swal.fire(`${this.$t('generic-str.deleted')}!`, this.$t('customer-component.product-removed'), 'success');
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
  },
};
</script>
<style scoped lang="scss"></style>
